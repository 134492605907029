import React from 'react'
import classNames from 'classnames'
import {Link} from 'gatsby';

class DocToc extends React.Component {

  render() {

    const className = classNames("DocToc sr4  ", this.props.className);

    return (
      <div className={className}>
        <div className="toc">
          <div className="toc-contents">
            <div className="toc-title">Docs</div>
            <div className="toc-item">
              <Link to="/doc">Overview</Link>
            </div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item"><Link to="/doc">Overview</Link></div>
            <div className="toc-item">
              <Link to="/doc/faq">Getting Started</Link>
            </div>
            <div className="toc-item">
              <Link to="/doc/faq">FAQ</Link>
            </div>
            <div className="toc-item">Guides</div>
            <div className="toc-item toc-indented-item">
              - <Link to="/doc/faq">Orgs</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default DocToc;
